
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

@Component
export default class TheMmcOrder extends Vue {
  @Prop(Boolean) readonly isAuth!: boolean
  @Prop(Number) readonly total!: number
  @Prop(String) readonly currencyName!: string
  @Prop(Number) readonly count!: number
  @Prop(Boolean) readonly isMmcKeyRequired!: boolean
  @Prop(String) readonly value!: string
  @Prop(Boolean) readonly isInvalidKey!: boolean

  key = ''

  get isDisabledButton () {
    if (!this.isAuth) return true
    if (!this.count) return true
    if (this.isMmcKeyRequired && !this.key) return true
    return false
  }

  sendOrder () {
    this.$emit('send-order', this.key)
  }

  eventInput () {
    this.$emit('change-input')
  }

  setKey () {
    this.key = this.value
  }

  mounted () {
    this.setKey()
  }

  @Watch("value")
  onWatchValue() {
    this.setKey()
  }
}
