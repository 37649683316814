import { render, staticRenderFns } from "./ModalMmcOrder.vue?vue&type=template&id=422c3ebf&lang=pug&"
import script from "./ModalMmcOrder.vue?vue&type=script&lang=ts&"
export * from "./ModalMmcOrder.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ModalMmcOrder.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports