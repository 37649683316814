
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MmcStoreInterface } from "@/interfaces/mmc-store"

@Component
export default class TheMmcSelectedModules extends Vue {
  @Prop(Array) readonly modules!: Array<MmcStoreInterface.Module>

  getName (names: Array<MmcStoreInterface.ModuleName>): string {
    const name: MmcStoreInterface.ModuleName = names.find(item => item.lang === this.$i18n.locale) || { lang: '', value: 'Без названия'}
    return name.value || this.$i18n.tc('no-name')
  }

  removeModule(id: string) {
    this.$emit('remove-module', id)
  }
}
