
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class TheMmcField extends Vue {
  @Prop(String) readonly value!: string
  $refs!: {
    input: HTMLInputElement;
  }

  query = ''
  prevSearchText: string | null = null

  eventInputText () {
    if (this.prevSearchText === this.query.trim()) return
    const startQuery = this.query.trim()
    setTimeout(() => { this.checkMatchSearchString(startQuery) }, 300)
  }

  checkMatchSearchString (startQuery: string) {
    if (startQuery === this.prevSearchText) return
    if (startQuery !== this.query.trim()) return
    this.prevSearchText = this.query.trim()
    this.$emit('search', startQuery)
  }

  setQuery () {
    this.query = this.value
  }

  clearSearch () {
    this.$emit('search', '')
    if (this.$refs.input) this.$refs.input.focus()
  }

  mounted () {
    this.setQuery()
  }

  @Watch("value")
  onWatchValue() {
    this.setQuery()
    this.prevSearchText = this.query.trim()
  }
}
