
import '@/assets/styles/imports/modal-thanks.sass'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ModalNote extends Vue {

  eventClose () {
    this.$emit('close')
  }
}
