
import {Component, Vue, Prop} from 'vue-property-decorator'

@Component
export default class TheMmcBrands extends Vue {
  @Prop(Array) readonly brands!: Array<string>

  position = 0
  isShowPrevButton = false
  isShowNextButton = false

  selectBrand(brand: string) {
    this.$emit('select-brand', brand)
  }

  goToPrevSlide () {
    let nextPosition = this.position - this.slider.getBoundingClientRect().width - 228
    if (nextPosition < 0) nextPosition = 0
    this.scrollSlider(nextPosition)
  }

  goToNextSlide () {
    const nextPosition = this.position + this.slider.getBoundingClientRect().width - 228
    this.scrollSlider(nextPosition)
  }

  scrollSlider (nextPosition: number)  {
    this.slider.scroll({
      left: nextPosition,
      behavior: "smooth"
    })
    this.position = nextPosition
    this.checkButtons()
  }

  checkButtons () {
    this.checkPrevButton()
    this.checkNextButton()
  }

  checkPrevButton () {
    this.isShowPrevButton = !(this.position <= 0)
  }

  checkNextButton () {
    this.isShowNextButton = this.position <= this.slider.scrollWidth - this.slider.clientWidth
  }

  getBrand(brand: string) {
    return brand.replace(/\s/g, "")
  }

  get slider () {
    return this.$el.getElementsByClassName('mmc-flash__brands-list')[0]
  }

  mounted () {
    this.checkButtons()
  }
}
