
import TheMmcFlash from "@/components/mms-flash/TheMmcFlash.vue"
import { Component, Vue } from "vue-property-decorator"

@Component({
  components: {
    TheMmcFlash
  }
})

export default class MMCFlashPage extends Vue {
}
