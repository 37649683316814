
import '@/assets/styles/imports/mmc-flash.sass'
import { Component, Vue } from 'vue-property-decorator'
import api from "@/api"
import storage from "@/storage";
import TheModules from "@/components/mms-flash/TheMmcTable.vue";
import TheMmcField from "@/components/mms-flash/TheMmcField.vue";
import TheMmcTable from "@/components/mms-flash/TheMmcTable.vue";
import TheMmcNote from "@/components/mms-flash/TheMmcNote.vue";
import TheMmcSelectedModules from "@/components/mms-flash/TheMmcSelectedModules.vue";
import TheMmcOrder from "@/components/mms-flash/TheMmcOrder.vue";
import { MmcStoreInterface} from "@/interfaces/mmc-store";
import TheMmcBrands from "@/components/mms-flash/TheMmcBrands.vue";
import ModalMmcOrder from "@/components/_modal/ModalMmcOrder.vue";
import getCurrencySymbol from "@/additionally/getCurrencySymbol";
import getCurrencyName from "@/additionally/getCurrencyName";
import ModalNote from "@/components/_modal/ModalNote.vue";
import ModalMmcError from "@/components/_modal/ModalMmcError.vue";
import ModalSuccessSubscription from "@/components/_modal/ModalSuccessSubscription.vue";
import ModalSubscription from "@/components/_modal/ModalSubscription.vue";
import ModalMmcThanks from "@/components/_modal/ModalMmcThanks.vue";
import {PaymentProvider, PaymentScriptParams} from "@/api/payment";
import ModalProcess from "@/components/_modal/ModalProcess.vue";
import { Errors } from "@/api/mmc-store";

@Component ({
  components: {
    ModalProcess,
    ModalMmcThanks,
    ModalSubscription,
    ModalSuccessSubscription,
    ModalMmcError,
    ModalNote,
    ModalMmcOrder,
    TheMmcBrands,
    TheMmcOrder,
    TheMmcSelectedModules,
    TheMmcNote,
    TheMmcTable,
    TheMmcField,
    TheModules
  },
})

export default class MmcFlash extends Vue {
  modulesFiltered: Array<MmcStoreInterface.Module> = []
  modulesChecked: Array<MmcStoreInterface.Module>  = []
  modulesAll: Array<MmcStoreInterface.Module>  = []
  brands: Array<string> = []
  checkedModulesId: Array<string> = []
  value = ''
  isAuth = true
  currencyId = 1
  mmcKey = ''
  isOpenPayModal = false
  isMmcKeyRequired = true
  isOpenModalError = false
  isOpenModalThanks = false
  isInvalidKey = false
  paymentScriptParams: PaymentScriptParams<PaymentProvider> | null = null
  provider: PaymentProvider = 'Robocassa'
  isShowProcessPayment = false
  errors: Errors | null = null

  startQuery (query: string) {
    this.modulesFiltered = []
    this.value = query
    const regexp = new RegExp(query, 'i')
    for (let i = 0; i < this.modulesAll.length; i++) {
      const currentModule: MmcStoreInterface.Module = this.modulesAll[i]
      const name = currentModule.names.find(item => item.lang === this.$i18n.locale)
      if (currentModule.brands && this.checkBrand(currentModule.brands, regexp) || name && this.checkName(name.value, regexp)) this.modulesFiltered.push(currentModule)
    }
  }

  searchBrand(brand: string) {
    this.value = brand
    this.startQuery(brand)
  }

  checkBrand (brands: Array<string>, regexp: RegExp): boolean {
    return brands.some(item => regexp.test(item))
  }

  checkName (name: string, regexp: RegExp): boolean {
    return regexp.test(name)
  }

  selectModules (ids: Array<string>) {
    this.checkedModulesId = ids
    this.modulesChecked = []
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i]
      const module = this.modulesAll.find(item => item.id === id)
      if (module) this.modulesChecked.push(module)
    }
  }

  removeModule(id: string) {
    this.modulesChecked = this.modulesChecked.filter(item => item.id !== id)
    this.checkedModulesId = this.checkedModulesId.filter(item => item !== id)
  }

  getCurrencyId() {
    const id = storage.user?.currencyId
    if (id) this.currencyId = id
  }

  openPayModal() {
    this.isOpenPayModal = true
  }

  closePayModal() {
    this.isOpenPayModal = false
  }

  setStartState () {
    this.checkedModulesId = []
    this.modulesChecked = []
  }

  showProcessPayment () {
    this.isShowProcessPayment = true
  }

  closeProcessPayment () {
    this.isShowProcessPayment = false
  }

  get currencySymbol(): string {
    return getCurrencySymbol(this.currencyId)
  }

  get currencyName(): string {
    return getCurrencyName(this.currencyId)
  }

  get total(): number {
    let total = 0
    for (let i = 0; i < this.modulesChecked.length; i++) {
      const currentModule = this.modulesChecked[i]
      const currentPrice = currentModule.price.find(item => item.cur === this.currencyName)
      if (currentPrice && currentPrice.price) total += currentPrice.price
    }
    return +total.toFixed(2)
  }

  async sendOrder(key: string) {
    this.errors = null
    this.mmcKey = key
    this.provider = await api.payment.getDefaultProvider() || 'Robocassa'
    this.paymentScriptParams = await api.mmcStore.getPaymentScriptParams({
      mmcFlashKey: this.mmcKey,
      moduleIds: this.checkedModulesId,
      currency: this.currencyName,
      total: this.total,
      provider: this.provider
    }, (errors: Errors) => this.errors = errors)
    if (this.errors && this.errors['message'] === 'MmcFlashKey is invalid') return this.isInvalidKey = true
    this.isInvalidKey = false
    if (!this.paymentScriptParams) return this.openModalError()
    this.openPayModal()
  }

  closeModalThanks () {
    this.isOpenModalThanks = false
  }

  openModalThanks () {
    this.isOpenModalThanks = true
  }

  closeModalError () {
    this.isOpenModalError = false
  }

  openModalError () {
    this.isOpenModalError = true
  }

  eventError () {
    this.closeProcessPayment()
    this.closePayModal()
    this.openModalError()
  }

  eventSuccess () {
    this.closeProcessPayment()
    this.closePayModal()
    this.openModalThanks()
  }

  hideError () {
    this.isInvalidKey = false
  }

  mounted () {
    this.getCurrencyId()
    api.mmcStore.getMmcStoreInfo().then(data => {
      if (data) {
        this.brands = data.brands
        this.isMmcKeyRequired = data.isMmcKeyRequired
        this.mmcKey = data.mmcFlashKey || ''
      }
    })
    api.mmcStore.getModules().then(data => {
      this.modulesFiltered = data.items
      this.modulesAll = data.items
    })
    api.authorize.isAuthenticated().then(data => this.isAuth = data)
  }
}
