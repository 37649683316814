
import '@/assets/styles/imports/modal-stock.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import api from '@/api'
import { MmcStoreInterface } from "@/interfaces/mmc-store";
import {PaymentProvider, PaymentScriptParams} from "@/api/payment";
import {PaymentInterface} from "@/interfaces/payment";
import PaymentScriptParamsProdamus = PaymentInterface.PaymentScriptParamsProdamus;
import PaymentScriptParamsRobocassa = PaymentInterface.PaymentScriptParamsRobocassa;
import ModalProcess from "@/components/_modal/ModalProcess.vue";
@Component({
  components: {ModalProcess}
})
export default class ModalMmcOrder extends Vue {
  @Prop({ type: Number }) readonly total!: number
  @Prop({ type: String }) readonly currencySymbol!: string
  @Prop({ type: String }) readonly currencyName!: string
  @Prop({ type: Array, default: () => [] }) readonly modulesId!: Array<string>
  @Prop({ type: Array, default: () => [] }) readonly modules!: Array<MmcStoreInterface.Module>
  @Prop({ type: String }) readonly mmcKey!: string
  @Prop({ type: Object}) readonly paymentScriptParams!: PaymentScriptParams<PaymentProvider>
  @Prop({ type: String }) readonly provider!: PaymentProvider

  runPaymentTimer = false
  requestCount = 120
  invId = ''
  isProdamusSuccess = false
  isProdamusError = false

  closeModal () {
    this.$emit('close')
  }

  async submitAction() {
    if (this.total === 0) {
      this.$emit('close')
    } else {
      if (this.provider === 'Prodamus') {
        this.addProdamusListeners()
        this.$emit('start-prodamus-payment')
        const paymentScriptParams = this.paymentScriptParams as PaymentScriptParams<'Prodamus'>
        // @ts-expect-error
        // eslint-disable-next-line
        payformInit('ecudtc.payform.ru', {
          // eslint-disable-next-line @typescript-eslint/camelcase
          order_sum: paymentScriptParams.sum,
          currency: paymentScriptParams.currency,
          // eslint-disable-next-line @typescript-eslint/camelcase
          order_id: paymentScriptParams.order_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          order_num: paymentScriptParams.order_num,
          // eslint-disable-next-line @typescript-eslint/camelcase
          customer_phone: paymentScriptParams.customer_phone,
          // eslint-disable-next-line @typescript-eslint/camelcase
          customer_email: paymentScriptParams.customer_email
        })
        await this.checkStatus(+(this.paymentScriptParams as PaymentScriptParamsProdamus).order_id)
      } else {
        // @ts-expect-error
        // eslint-disable-next-line
        Robokassa.StartPayment(this.paymentScriptParams)
        await this.checkStatus(+(this.paymentScriptParams as PaymentScriptParamsRobocassa).invId)
      }
    }
  }

  async checkStatus (invId: number) {
    this.runPaymentTimer = true
    const payment = await this.checkPaymentStatus(invId)
    if (!payment) return
    this.closeModal()
    this.$emit("success-paid", (this.paymentScriptParams as PaymentScriptParamsRobocassa).invId)
  }

  async checkPaymentStatus (invId: number): Promise<boolean> {
    for (let i = 0; i < this.requestCount; i++) {
      if (!this.runPaymentTimer) {
        return false
      }
      const status = await api.mmcStore.checkPayStatus(invId)
      if (status === 'Paid') return true
      if (i === this.requestCount - 1 || status === 'Error') this.$emit("error-paid")
      await this.timeout()
    }
    return false
  }

  getName (names: Array<MmcStoreInterface.ModuleName>): string {
    const name: MmcStoreInterface.ModuleName = names.find(item => item.lang === this.$i18n.locale) || { lang: '', value: 'Без названия'}
    return name.value || 'Без названия'
  }

  getPrice(price: Array<MmcStoreInterface.ModulePrice>): number {
    const current = price.find(item => item.cur === this.currencyName)
    if (current) return current.price
    return 0
  }

  timeout (): Promise<null> {
    return new Promise(resolve => { setTimeout(resolve, 4000) })
  }

  eventProdamus (event: any) {
    if (event.data.status === "close") {
      if (this.isProdamusSuccess) return
      this.$emit('cancel-prodamus-payment')
      if (this.isProdamusError) {
        this.$emit('prodamus-error')
      }
    }
    if (event.data.status === "success") {
      this.isProdamusSuccess = true
    }
    if (event.data.status === "error") {
      this.isProdamusError = true
    }
  }

  addProdamusListeners () {
    window.addEventListener("message", this.eventProdamus)
  }

  removeProdamusListeners () {
    window.addEventListener("message", this.eventProdamus)
  }

  mounted () {
    if (this.provider === 'Prodamus') {
      this.invId = (this.paymentScriptParams as PaymentScriptParams<'Prodamus'>).order_id
    } else {
      this.invId = (this.paymentScriptParams as PaymentScriptParams<'Robocassa'>).invId
    }
  }

  destroyed () {
    this.runPaymentTimer = false
    this.removeProdamusListeners()
  }
}
