
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import { MmcStoreInterface} from "@/interfaces/mmc-store";

@Component({})

export default class TheMmcTable extends Vue {
  @Prop(String) readonly value!: string
  @Prop(Array) readonly modules!: Array<MmcStoreInterface.Module>
  @Prop(Array) readonly checkedModulesId!: Array<string>
  @Prop(String) readonly currencyName!: string
  @Prop(String) readonly currencySymbol!: string

  query = ''
  prevSearchText: string | null = null
  headers = ['name', 'price', 'options','brand']
  modulesId: Array<string> = []
  isOpenModalDescription = false
  description = ""

  getName (names: Array<MmcStoreInterface.ModuleName>): string {
    const name: MmcStoreInterface.ModuleName = names.find(item => item.lang === this.$i18n.locale) || { lang: '', value: ''}
    return name.value || this.$i18n.tc('no-name')
  }

  changeModules () {
    this.$emit('select-modules', this.modulesId)
  }

  getTitle(descr: Array<MmcStoreInterface.ModuleDescr>) {
    const currentDescr: MmcStoreInterface.ModuleDescr = descr.find(item => item.lang === this.$i18n.locale) || { lang: '', value: ''}
    return currentDescr.value
  }

  getPrice(price: Array<MmcStoreInterface.ModulePrice>): number {
    const current = price.find(item => item.cur === this.currencyName)
    if (current) return current.price
    return 0
  }

  getDescription(descr: Array<MmcStoreInterface.ModuleDescr>): string {
    const currentDescriptionLang = descr.find(item => item.lang === this.$i18n.locale) || { lang: '', value: ''}
    return currentDescriptionLang.value
  }

  getBrand(brand: string) {
    return brand.replace(/\s/g, "")
  }

  openModalDescription (module: MmcStoreInterface.Module) {
    this.isOpenModalDescription = true
    const currentDescriptionLang = module.descr.find(item => item.lang === this.$i18n.locale) || { lang: '', value: ''}
    this.description = currentDescriptionLang.value
  }

  mounted () {
    this.query = this.value
    this.modulesId = this.checkedModulesId
  }

  @Watch("checkedModulesId")
  onWatchCheckedModulesId() {
    this.modulesId = this.checkedModulesId
  }
}
